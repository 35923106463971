<template>
  <SchoolTeacherLeaveApplicationControl
    v-if="type === 1"
  ></SchoolTeacherLeaveApplicationControl>
  <SchoolStaffLeaveApplicationControl
    v-else-if="type === 2"
  ></SchoolStaffLeaveApplicationControl>
</template>

<script>
import SchoolStaffLeaveApplicationControl from "@/modules/school/components/management/leave/application/control/SchoolStaffLeaveApplicationControl";
import SchoolTeacherLeaveApplicationControl from "@/modules/school/components/management/leave/application/control/SchoolTeacherLeaveApplicationControl";

export default {
  name: "SchoolLeaveApplicationTableControl",
  components: {
    SchoolTeacherLeaveApplicationControl,
    SchoolStaffLeaveApplicationControl,
  },
  data() {
    return {
      type: 1,
    };
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },

  methods: {
    checkRoute(name) {
      switch (name) {
        case "schoolLeaveApplication":
          this.type = 1;
          break;
        case "staffLeaveApplication":
          this.type = 2;
          break;
        default:
          this.type = 1;
          break;
      }
    },
  },

  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
