<template>
  <AppTableControl
    no-export
    no-filter
    @query-debounced="searchQuery"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "SchoolTeacherLeaveApplicationControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeTeacherLeaveApplicationApprovalBaseFilter", {
      query: null,
    });
  },
  methods: {
    /**
     * Search the given query
     * @param query
     */
    searchQuery(query) {
      this.$store.dispatch("changeTeacherLeaveApplicationApprovalBaseFilter", {
        query,
      });

      this.$store.dispatch("fetchTeacherLeaveApplicationApprovalList");
    },
  },
};
</script>

<style scoped></style>
