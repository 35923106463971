<template>
  <AppTableControl
    no-filter
    no-export
    @query-debounced="searchQuery"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "SchoolStaffLeaveApplicationControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeStaffLeaveApplicationApprovalAllFilter", {
      query: null,
    });
  },
  methods: {
    /**
     * Search the given query.
     *
     * @param query
     */
    searchQuery(query) {
      console.log(query);

      this.$store.dispatch("changeStaffLeaveApplicationApprovalAllFilter", {
        query,
      });
      this.$store.dispatch("fetchStaffLeaveApplicationApprovalList");
    },
  },
};
</script>

<style scoped></style>
